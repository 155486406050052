import React, {useState, useEffect} from 'react';
import { Post, IArticle} from './Post';
import { client } from '../client';

export interface IPosts {
    posts: IArticle[]
}

export const Posts = ({posts} : IPosts) => {
    const [articles, setArticles] = useState<IArticle[]>([]);
    const [tmp] = useState();
    const fetchData = async() => {
        try {
          const resp = await client.getEntries ({content_type: 'easysite'});
          setArticles(resp.items as any);
        } catch (error) {
          console.log(error);
        }
    
      }
    
    useEffect(() => {
            fetchData();
        
    }, [tmp]);

    return (<main role="main"><div className="jumbotron">
      <div className="container">
      <h1 className="display-3">Listing Page</h1>
      <p>This is a template for a simple marketing or informational website. It includes a large callout called a jumbotron and three supporting pieces of content. Use it as a starting point to create something more unique.</p>
      <p><a className="btn btn-primary btn-lg" href="#" role="button">Learn more &raquo;</a></p>
    
    </div>
    </div>
    <div className="container">
    <div className="row">
      {articles.map((article: any, index: number) => <Post
                article={article}
                key={index}
              />
            )}
    </div>
    </div>
    </main>);
}
