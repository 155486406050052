import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import { Posts } from './components/Posts';
import { Page } from './components/Page';
import { useAuth0 } from '@auth0/auth0-react';

function App() {
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    logout,
  } = useAuth0();

  const RedirectToLogin: React.FC = () => {
    loginWithRedirect();
    return <></>;
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }
  return(<>
  <header><nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark pt-3 pb-3">
      <a href="https://panatech.c1x.biz/">
        <img src="https://archives.connect.panasonic.com/sav/common/global-libs/images/lgo-nav-global-panasonic.png" alt="Panasonic Business" width="80%" />
      </a>
    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
    </button>

    <div className="collapse navbar-collapse" id="navbarsExampleDefault">
    <ul className="navbar-nav mr-auto">
      <li className="nav-item active">
        <a className="nav-link" href="/">Home <span className="sr-only">(current)</span></a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#">Link</a>
      </li>
      <li className="nav-item">
        <a className="nav-link disabled" href="#">Disabled</a>
      </li>
      <li className="nav-item dropdown">
        <a className="nav-link dropdown-toggle" href="http://example.com" id="dropdown01" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Dropdown</a>
        <div className="dropdown-menu" aria-labelledby="dropdown01">
          <a className="dropdown-item" href="#">Action</a>
          <a className="dropdown-item" href="#">Another action</a>
          <a className="dropdown-item" href="#">Something else here</a>
        </div>
      </li>
    </ul>
{
          (() => {
            if (isAuthenticated) {
              return (
                <form className="form-inline my-2 my-lg-0">
                  <label className="col-sm-12" style={{color:'#fff'}}>{user && user.name}{' '}&nbsp;
                  <button className="btn btn-outline-light my-2 my-sm-0" 
                    onClick={() => {
                      logout({ returnTo: window.location.origin });
                    }}
                  >
                    Log out
                  </button></label>
              </form>

              );
            } else {
              return (
                <form className="form-inline my-2 my-lg-0">
                <button className="btn btn-outline-light my-2 my-sm-0" onClick={loginWithRedirect}>
                  My Panasonic
                </button>
            </form>
              );
            }
          })()
        }
    </div>
  </nav></header>
  {
          (() => {
            if (isAuthenticated) {
              return (
                <BrowserRouter basename='/'>
                  <Switch>
                      <Route key="post" exact path="/" component={Posts} />
                      <Route key="page" path="/page/:id" component={Page} />
                  </Switch>
                </BrowserRouter>
              );
            } else {
              //return(<RedirectToLogin />);
              
              return (<main role="main"><div className="jumbotron">
              <div className="container text-center">
              <h1 className="display-3">Login Page</h1>
              <p>This is a template for a simple marketing or informational website. It includes a large callout called a jumbotron and three supporting pieces of content. Use it as a starting point to create something more unique.</p>
            </div>
            </div>
            <div className="container">
            <div className="row">
                <form className="form-signin">
                <div className="text-center mb-4">
                  <h1 className="h3 mb-3 font-weight-normal">Floating labels</h1>
                  <p>Build form controls with floating labels via the pseudo-element.</p>
                </div>
                <button onClick={loginWithRedirect} className="btn btn-lg btn-primary btn-block">Log in</button>
              </form>
              </div></div></main>);
            }
          })()
        }

  <footer className="container"><p>2017-2018 Company, Inc. <a href="#">Privacy</a></p></footer>
</>);
}

export default App;
