import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import marked from 'marked';
import { client } from '../client';

export interface IArticle2 {
    fields: {
        title: string;
        description: string;
        image: IImage
    }
};

interface IImage {
    fields: {
        file: {
            url: string;
        }
    }
};
export const getFileName = () => {
    return window.location.href.split('/').pop();
}

export const Page = () => {
    const [article, setArticle] = useState<IArticle2>();
    const [tmp] = useState();
    const fetchDataPage = async(id:any) => {
      try {
        //const resp = await client.getEntry(id);
        const resp = await client.getEntries({
          content_type: 'easysite',
          'fields.slug': id,
          limit: 1
        });
        console.log(JSON.stringify(resp,null,2));
        setArticle(resp.items[0] as any);
      } catch (error) {
        console.log(error);
      }
    };

    useEffect(() => {
        fetchDataPage(getFileName());
    }, [tmp]);

    return (<main role="main" dangerouslySetInnerHTML={article && { __html: marked(article.fields.description)}} />);
}
