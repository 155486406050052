import React from 'react'
import { Link } from 'react-router-dom';

export interface IArticle {
    article: {
        fields: {
            title: string;
            description: string;
            image: IImage;
            slug: string;
        },
        sys: {
            id: string;
        }
    }
};

interface IImage {
    fields: {
        file: {
            url: string;
        }
    }
};

export const Post = ({ article }: IArticle) => {
    // console.log(article);
    const { title } =article.fields;
    //const postDescription = marked(description);
    return (
        <div className="col-md-4">
        <h2>Heading</h2>
        <p>Donec sed odio dui. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Vestibulum id ligula porta felis euismod semper. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.</p>
        <p><Link className='btn btn-secondary' to={"/page/"+article.fields.slug}>{title}</Link></p>
      </div>
    )
}
